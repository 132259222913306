@use "@utils/variables" as v;

.wrapper {
    display: flex;
    width: 100%;
    height: v.$navbarHeight;
    padding-block: 0em;
    background-color: white;
    box-shadow: 1px 1px 10px rgba(1, 1, 1, 0.2);
    border-bottom: 1px solid rgba(1, 1, 1, 0.1);

    z-index: 10;

    .logo {
        cursor: pointer;
        height: 100%;

        img {
            width: 180px;
            height: 200px;
            z-index: 12;
            margin-top: -72px;
            margin-left: -40px;
        }
    }

    .ham {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }

    position: relative;

    .links {
        display: flex;
        align-items: center;
        margin-left: auto;

        ul {
            display: flex;
            align-items: center;
            list-style-type: none;
            height: 100%;

            li {
                display: flex;
                align-items: center;
                margin-right: 2em;
                position: relative;
                height: 100%;
                min-width: 50px;
                text-align: center;

                a {
                    color: black;
                    text-decoration: none;
                    font-weight: 500;
                    text-align: center;
                    width: 100%;
                    padding: 0.5em;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            .linkActive::after {
                content: "";
                display: inline-block;
                position: absolute;
                width: 100%;
                height: 8px;
                bottom: -1px;
                left: 0;
                background-color: v.$themeBlue;

                border-top-right-radius: 20px;
                border-top-left-radius: 20px;
            }
        }
    }

    .links.mobileView {
        position: absolute;
        top: calc(v.$navbarHeight + 0px);
        right: 0;
        width: 100%;
        height: 0;
        overflow-y: hidden;
        background-color: white;
        align-items: unset;
        transition: height 0.5s;

        ul {
            width: 100%;
            flex-direction: column;
            margin-block: 0;
            padding: 0;

            li {
                margin-right: 0em;
                padding: 0.6em;
                height: 50px;
            }
        }
    }

    .links.mobileView.open {
        height: 400px;
        background-color: white;
        border-bottom: 1px solid rgba(1, 1, 1, 0.1);
    }
}

@media screen and (max-width: 600px) {
    .wrapper {
        .logo {
            img {
                width: 150px;
                height: 180px;
                margin-top: -62px;
            }
        }
    }
}
