@use "@utils/variables" as v;

.wrapper {
    padding-inline: v.$inlinePadding;
    width: 100%;
}

@media screen and (min-width: 1400px) {
    .wrapper {
        padding-inline: 120px;
    }
}

@media screen and (min-width: 1500px) {
    .wrapper {
        padding-inline: 150px;
    }
}

@media screen and (min-width: 1600px) {
    .wrapper {
        padding-inline: 200px;
    }
}

@media screen and (min-width: 1700px) {
    .wrapper {
        padding-inline: 250px;
    }
}

@media screen and (min-width: 1900px) {
    .wrapper {
        padding-inline: 350px;
    }
}

@media screen and (min-width: 2000px) {
    .wrapper {
        padding-inline: 20%;
    }
}

@media screen and (max-width: 1200px) {
    .wrapper {
        padding-inline: 80px;
    }
}

@media screen and (max-width: 1000px) {
    .wrapper {
        padding-inline: 60px;
    }
}

@media screen and (max-width: 750px) {
    .wrapper {
        padding-inline: 50px;
    }
}

@media screen and (max-width: 600px) {
    .wrapper {
        padding-inline: 30px;
    }
}
@media screen and (max-width: 500px) {
    .wrapper {
        padding-inline: 20px;
    }
}

@media screen and (max-width: 400px) {
    .wrapper {
        padding-inline: 15px;
    }
}
