@use "@utils/variables" as v;

.swiper-slide {
    padding: 1.5em;
}

#blog_gallery_section {
    .swiper-slide {
        padding: 0;
    }

    .swiper-button-next,
    .swiper-button-prev {
        background-color: rgb(209, 209, 209, 0.5);
        border-radius: 50%;
        width: 60px;
        height: 60px;
        right: 30px;
    }

    .swiper-button-prev {
        left: 30px;
    }

    .swiper-button-next:after {
        font-size: 1em;
        color: white;
    }
    .swiper-button-prev:after {
        font-size: 1em;
        color: white;
    }
}

#offer_products_carousel {
    .swiper-button-next,
    .swiper-button-prev {
        top: 44%;
        background-color: rgb(209, 209, 209, 0.8);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        right: 45px;
    }

    .swiper-button-prev {
        left: 45px;
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
        font-weight: 700;
        font-size: 1em;
        color: v.$themePurple;
    }
}

#home_courses {
    .swiper-slide {
        width: 460px;
    }

    .swiper-pagination {
        .swiper-pagination-bullet {
            background-color: rbga(1, 1, 1, 0.2);
        }
        .swiper-pagination-bullet-active {
            background-color: v.$themePurple;
        }
    }
}

#home_testimonials {
    .swiper-slide {
        width: 500px !important;
    }

    .swiper-pagination {
        .swiper-pagination-bullet {
            background-color: rbga(1, 1, 1, 0.2) !important;
        }
        .swiper-pagination-bullet-active {
            background-color: v.$themePurple !important;
        }
    }
}

#teams_carousel {
    .swiper-slide {
        width: 400px;
    }
}

@media screen and (max-width: 600px) {
    #home_testimonials {
        .swiper-slide {
            padding: 1em;
            width: 100% !important;
        }
    }

    #home_courses {
        .swiper-slide {
            width: 380px;
        }
    }
}
