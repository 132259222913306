.wrapper {
    background-color: #f0fcff;
    width: 100%;
    padding-top: 3em;
    padding-bottom: 1em;

    .inner {
        .top {
            display: flex;

            section {
                display: flex;
            }

            article {
                color: #afb5b6;
                margin-right: 2em;

                header {
                    margin-bottom: 2em;
                    color: black;
                }
            }

            article.logo {
                width: 20%;
                margin-right: 0;

                img {
                    width: 300px;
                    margin-top: -110px;
                    margin-left: -82px;
                }
            }

            article.linksSection {
                width: 10%;

                .links {
                    display: flex;
                    flex-direction: column;

                    a {
                        text-decoration: none;
                        color: unset;
                        color: #afb5b6;
                        margin-bottom: 1em;
                    }
                }
            }

            article.address {
                width: 20%;

                div {
                    line-height: 29px;
                }
            }

            article.contact {
                width: 20%;

                div {
                    margin-bottom: 13px;
                }
            }

            article.socialHandles {
                width: 20%;

                img {
                    width: 30px;
                    height: 30px;
                    margin-right: 10px;
                }
            }
        }

        .bottom {
            width: 100%;
            color: #888;
            font-size: 13px;
            line-height: 19px;
        }
    }
}

@media screen and (max-width: 900px) {
    .wrapper {
        padding-top: 4em;

        .inner {
            .top {
                flex-direction: column;

                article.logo {
                    display: flex;
                    width: 100%;
                    justify-content: center;

                    img {
                        width: 250px;
                        margin-top: -120px;
                        margin-left: 0px;
                    }
                }

                article {
                    margin-right: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100% !important;
                    margin-bottom: 2em;

                    header {
                        margin-bottom: 1em;
                    }
                }

                article.address {
                    div {
                        text-align: center;
                    }
                }

                article.linksSection {
                    margin-top: -4em;
                    .links {
                        a {
                            text-align: center;
                            margin-bottom: 0.5em;
                        }
                    }
                }

                article.contact {
                    div {
                        margin-bottom: 0.5em;
                    }
                }
            }
        }
    }
}
